<template>
	<standard-container title="Formazione Corsi" description="" admin>
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabCorsi">
					Corsi e date
				</v-tab>
				<v-tab key="tabCalendario">
					Calendario
				</v-tab>
				<v-tab key="tabDocenti">
					Docenti
				</v-tab>
				<v-tab key="tabSedi">
					Sedi
				</v-tab>
				<v-tab key="tabRichieste">
					Richieste di formazione
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-btn v-if="currentTab===0 && fabVisible" fab color="warning" top right absolute title="Aggiungi corso" @click="aggiungiCorso">
					<v-icon>mdi-plus</v-icon>
				</v-btn>						
				<v-btn v-if="currentTab===2 && fabVisible" fab color="warning" top right absolute title="Aggiungi docente" @click="aggiungiDocente">
					<v-icon>mdi-plus</v-icon>
				</v-btn>						
				<v-btn v-if="currentTab===3 && fabVisible" fab color="warning" top right absolute title="Aggiungi sede" @click="aggiungiSede">
					<v-icon>mdi-plus</v-icon>
				</v-btn>						

				<v-tabs-items v-model="currentTab">
					<!-- tabCorsi -->
					<v-tab-item key="tabCorsi">
						<hr-anno-riferimento v-model="annoCorrente" @change="changeAnnoCorrente" />		

						<v-data-table dense
							:loading="loadingCorsi"
							:items="corsiConDateOrdinate" 
							:headers="tableHeaderCorsi"
							item-key="Id"
							:sort-by="['PrimaDataUtile']" 
							:sort-desc="[false]"
							hide-default-footer
							disable-pagination
							show-expand
							:single-expand="false"
							@click:row="editCorso"
						>
							<template v-slot:item.Id="{ item }">
								<v-chip x-small label :color="item.Colore" class="pl-0" />
							</template>
							<template v-slot:item.IdJob="{ item }">
								<standard-label>{{ decodificaDescrizioneJob(item.IdJob) }}</standard-label>
							</template>
							<template v-slot:item.CodiceFinalita="{ item }">
								<standard-label>{{ decodificaFinalita(item.CodiceFinalita) }}</standard-label>
							</template>
							<template v-slot:item.IdDocentePrincipale="{ item }">
								<div class="d-flex justify-space-between">
									<standard-label>{{ decodificaNomeDocente(item.IdDocentePrincipale) }}</standard-label>
									<div v-if="item.IdDocentePrincipale>0" class="ml-2">
										<v-chip v-if="decodificaTipoDocente(item.IdDocentePrincipale)" color="info" x-small label>
											interno
										</v-chip>
										<v-chip v-else color="" x-small label>
											esterno
										</v-chip>
									</div>
								</div>
							</template>
							<template v-slot:item.IdSedePrincipale="{ item }">
								<standard-label>{{ decodificaNomeSede(item.IdSedePrincipale) }}</standard-label>
							</template>
							<template v-slot:item.PrimaDataUtile="{ item }">
								<standard-label>{{ gShortDate(item.PrimaDataUtile) }}</standard-label>
							</template>
							<template v-slot:item.UltimaDataUtile="{ item }">
								<standard-label>{{ gShortDate(item.UltimaDataUtile) }}</standard-label>
							</template>
							<template v-slot:item.Trimestre="{ item }">
								<standard-label>{{ decodificaTrimestre(item.Trimestre ) }}</standard-label>
							</template>
							<template v-slot:item.IsEliminable="{ item }">
								<v-btn v-if="item.IsEliminable" right x-small color="warning" icon title="cancella corso" @click.stop="eliminaCorso(item)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<div class="mt-2 mb-5 d-flex flex-column flex-lg-row justify-lg-space-between">
										<v-card style="background-color:rgba(255,0,0,0.025)" class="mt-1">
											<v-card-title class="body-1">
												Date del corso "{{ item.Codice }}"
											</v-card-title>
											<v-card-text>
												<table class="legacyTable">
													<thead>
														<tr>
															<th>Desc</th>
															<th>Inizio</th>
															<th>Fine</th>
															<th>Timestre</th>
															<th>Sede</th>
															<th>Docente</th>
															<th>Totale ore</th>
															<th>Costo orario</th>
														</tr>
													</thead>
													<tbody v-for="data in item.Date" :key="data.Id">
														<tr>
															<td class="body-2">
																{{ data.Nome }}
															</td>
															<td class="caption">
																{{ gShortDate(data.Inizio) }}
															</td>
															<td class="caption">
																{{ gShortDate(data.Fine) }}
															</td>
															<td class="caption">
																{{ decodificaTrimestre(data.Trimestre) }}
															</td>
															<td class="caption">
																{{ decodificaNomeSede(data.IdSede) }}
															</td>
															<td class="caption">
																<div class="d-flex justify-space-between">
																	{{ decodificaNomeDocente(data.IdDocente) }}
																	<div v-if="data.IdDocente>0" class="ml-2">
																		<v-chip v-if="decodificaTipoDocente(data.IdDocente)" color="info" x-small label>
																			interno
																		</v-chip>
																		<v-chip v-else color="" x-small label>
																			esterno
																		</v-chip>
																	</div>
																</div>
															</td>
															<td class="caption">
																{{ data.OreData }}
															</td>
															<td class="caption">
																{{ data.CostoData }}
															</td>
														</tr>
													</tbody>
												</table>
											</v-card-text>
										</v-card>
										<v-card style="background-color:rgba(0,0,255,0.025)" class="mt-1">
											<v-card-title class="body-1">
												Descrizione del corso "{{ item.Codice }}"
											</v-card-title>
											<v-card-text>
												<pre>{{ item.Descrizione }}</pre>
											</v-card-text>
										</v-card>
									</div>
								</td>
							</template>
						</v-data-table>
					</v-tab-item>

					<!-- tabCalendario -->
					<v-tab-item key="tabCalendario">
						<hr-calendario-corsi :value="calendario" :enable-update-anno-corrente="true" :anno-corrente.sync="annoCorrente" is-editable :show-label="false" @change:anno-corrente="changeAnnoCorrente" @edit="editCorsoFromCalendario">
							<template v-slot:header>
								<hr-anno-riferimento v-model="annoCorrente" @change="changeAnnoCorrente" />		
							</template>
						</hr-calendario-corsi>
					</v-tab-item>

					<!-- tabDocenti -->
					<v-tab-item key="tabDocenti">
						<v-data-table 
							:loading="loadingDocenti"
							:items="docenti" 
							:headers="tableHeaderDocenti"
							item-key="Id"
							hide-default-footer
							disable-pagination
							@click:row="editDocente"
						>
							<template v-slot:item.Interno="{ item }">
								<v-chip v-if="item.Interno" x-small label color="success">
									interno
								</v-chip>
								<v-chip v-else x-small label color="info">
									esterno
								</v-chip>
							</template>
							<template v-slot:item.IsEliminable="{ item }">
								<v-btn v-if="item.IsEliminable" right x-small color="warning" icon title="cancella docente" @click.stop="eliminaDocente(item)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
						</v-data-table>						
					</v-tab-item>

					<!-- tabSedi -->
					<v-tab-item key="tabSedi">
						<v-data-table 
							:loading="loadingSedi"
							:items="sedi" 
							:headers="tableHeaderSedi"
							item-key="Id"
							hide-default-footer
							disable-pagination
							@click:row="editSede"
						>
							<template v-slot:item.IsEliminable="{ item }">
								<v-btn v-if="item.IsEliminable" right x-small color="warning" icon title="cancella sede" @click.stop="eliminaSede(item)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
						</v-data-table>						
					</v-tab-item>

					<!-- tabRichieste -->
					<v-tab-item key="tabRichieste">
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<td colspan="3">
											Richieste correnti
										</td>
									</tr>
									<tr>
										<th class="text-left">
											Data
										</th>
										<th class="text-left">
											Stato
										</th>
										<th class="text-left">
											Persona
										</th>
										<th class="text-left">
											Descrizione
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in richiesteExt" :key="item.Id" @click="editRichiesta(item)">
										<td class="clickable">
											{{ gStandardDate(item.DataRichiesta) }}
										</td>
										<td class="clickable">
											<v-chip v-if="item.StatoRichiestaFormazione===10" label small color="primary">
												nuova
											</v-chip>
											<v-chip v-if="item.StatoRichiestaFormazione===20" label small color="warning">
												in valutazione
											</v-chip>
											<v-chip v-if="item.StatoRichiestaFormazione===30" label small color="success">
												programmata
											</v-chip>
											<v-chip v-if="item.StatoRichiestaFormazione===40" label small color="success">
												effettuata
											</v-chip>
											<v-chip v-if="item.StatoRichiestaFormazione===50" label small color="error">
												rifiutata
											</v-chip>
										</td>
										<td class="clickable">
											<v-avatar :size="35">
												<img v-if="item.Persona.Immagine" alt="Avatar" :src="imagesPathPerson + item.Persona.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
												<avatar v-else :username="item.Persona.FullName" :size="35" />
											</v-avatar>
											<label style="margin-left:10px">{{ item.Persona.FullName }}</label>
										</td>
										<td class="clickable">
											{{ item.DescrizioneRichiesta | truncate(70) }}
										</td>
										<!--
										<td>
											<v-btn v-if="item.StatoRichiestaFormazione===10" x-small title="Elimina" @click.stop="deleteRichiesta(item)" class="ml-5" icon color="warning"><v-icon>mdi-delete</v-icon></v-btn>
										</td>
										-->
									</tr>
								</tbody>
							</template>
						</v-simple-table>  
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="corsi">
						{{ corsi }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="calendario">
						{{ calendario }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="docenti">
						{{ docenti }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="sedi">
						{{ sedi }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>

		<!-- footer -->
		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon title="Ricarica" :disabled="loading" :loading="loading" @click="loadData">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</template>	

		<!-- inizio dialog corso -->
		<v-dialog v-model="dialogCorso" :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md'" persistent transition="scale-transition" max-width="1200" @keydown.esc="dialogCorso = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialogCorso = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentCorso.New ? 'Nuovo' : 'Modifica' }} corso</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loadingCorsi" :loading="loadingCorsi" @click="updateCorso">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-alert v-if="errorCorso" type="error" dense text>
						{{ errorCorso }}
					</v-alert>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="8">
							<v-text-field v-model="currentCorso.Codice" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Codice" hint="Il codice del corso deve essere univoco" persistent-hint required />
						</v-col>
						<v-col cols="12" lg="4">
							<v-autocomplete v-model="currentCorso.Colore" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" auto-select-first :color="currentCorso.Colore" label="Colore" hint="Serve per la visualizzazione nel calendario" persistent-hint :items="calendarioColori" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentCorso.Descrizione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione" rows="2" auto-grow />
						</v-col>
						<v-col cols="12" lg="6">
							<v-select v-model="currentCorso.CodiceFinalita" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Finalità" :items="decodifiche.FormazioneFinalita" item-value="Key" item-text="Value" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-autocomplete v-model="currentCorso.IdJob" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" auto-select-first label="Job" :items="decodifiche.Jobs" item-value="Id" item-text="DescrizioneJob" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-autocomplete v-model="currentCorso.IdDocentePrincipale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" auto-select-first label="Docente principale" hint="Se compilato diventerà il docente di default per tutte le date" persistent-hint :items="docenti" item-value="Id" item-text="FullName" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-autocomplete v-model="currentCorso.IdSedePrincipale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" auto-select-first label="Sede principale" hint="Se compilato diventerà la sede di default per tutte le date" persistent-hint :items="sedi" item-value="Id" item-text="Nome" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-switch v-model="currentCorso.Finanziato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Formazione finanziata" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="currentCorso.Costo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Costo orario standard" hint="Viene utilizzato solo quando il costo per data è pari a zero" />
						</v-col>
					</v-row>
					<v-divider />
					<div class="d-flex justify-space-between mt-5" style="width:100%">
						<div class="title">
							Date
						</div>
						<v-btn x-small color="warning" @click="aggiungiData">
							<v-icon small left>
								mdi-plus
							</v-icon>aggiungi date
						</v-btn>
					</div>
				</v-container>
				<v-card-text :style="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md') ? 'padding-left:10px;' : 'height: 400px;min-height: 400px;overflow-y:auto'">
					<v-sheet v-for="(data, index) in currentCorso.Date" :key="index" color="rgba(220,235,245,0.3)" class="pa-3 mb-2 elevation-1 d-lg-flex justify-space-between align-center">
						<v-text-field v-model="data.Nome" :disabled="data.Delete" dense label="Nome" hide-details class="mr-3" />
						<!-- 
						<hr-date-picker :disabled="data.Delete" dense clearable :clearable-at-null="false" label="Data inizio" hide-details v-model="data.Inizio" @change="changeDateInizioFine($event, data)" short-date class="mr-3"></hr-date-picker>
						<hr-date-picker :disabled="data.Delete" dense clearable :clearable-at-null="false" label="Data fine" hide-details v-model="data.Fine" :min="data.Inizio" @change="changeDateInizioFine($event, data)" short-date class="mr-3"></hr-date-picker>
						-->
						<hr-formazione-range-date-picker
							:disabled="data.Delete" dense clearable label="Date inizio - fine" hide-details short-date class="mr-3"
							:inizio.sync="data.Inizio"
							:fine.sync="data.Fine"
							:show-events="true"
							:calendario="calendario"
						/>

						<v-autocomplete v-model="data.IdDocente" :disabled="data.Delete" dense auto-select-first label="Docente" hide-details :items="docenti" item-value="Id" item-text="FullName" class="mr-3" />
						<v-autocomplete v-model="data.IdSede" :disabled="data.Delete" dense auto-select-first label="Sede" hide-details :items="sedi" item-value="Id" item-text="Nome" class="mr-3" />
						<v-text-field v-model="data.CostoData" :disabled="data.Delete" dense label="Costo orario" hide-details class="mr-3" />
						<v-text-field v-model="data.OreData" :disabled="data.Delete" dense label="Ore totali" hide-details class="mr-3" />
						<v-btn v-if="data.IsEliminable" class="ml-0" x-small color="warning" icon title="cancella data" @click.stop="data.Delete = !data.Delete">
							<v-icon>{{ data.Delete ? 'mdi-delete-off' : 'mdi-delete' }}</v-icon>
						</v-btn>
					</v-sheet>
				</v-card-text>
			</v-card>
		</v-dialog>									
		<!-- fine dialog corso -->

		<!-- inizio dialog docente -->
		<v-dialog v-model="dialogDocente" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogDocente = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialogDocente = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentDocente.New ? 'Nuovo' : 'Modifica' }} docente</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loadingDocenti" :loading="loadingDocenti" @click="updateDocente">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="6">
							<v-text-field v-model="currentDocente.Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Nome" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-switch v-model="currentDocente.Interno" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Interno" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentDocente.Descrizione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione" rows="2" auto-grow />
						</v-col>
					</v-row>
					<v-alert v-if="errorDocente" type="error" dense text>
						{{ errorDocente }}
					</v-alert>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog docente -->				

		<!-- inizio dialog sede -->
		<v-dialog v-model="dialogSede" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogSede = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialogSede = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentSede.New ? 'Nuova' : 'Modifica' }} sede</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loadingSedi" :loading="loadingSedi" @click="updateSede">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentSede.Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Nome" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentSede.Descrizione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione" rows="2" auto-grow />
						</v-col>
					</v-row>
					<v-alert v-if="errorSede" type="error" dense text>
						{{ errorSede }}
					</v-alert>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog sede -->	

		<!-- inizio dialog richiesta -->
		<v-dialog v-if="currentRichiesta" v-model="dialogRichiesta" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogRichiesta = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialogRichiesta = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-text="(currentRichiesta.Id<0 ? 'Nuova' : 'Modifica') + ' richiesta'" />
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loadingRichieste" :loading="loadingRichieste" @click="updateRichiesta">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="12">
							<v-avatar :size="35">
								<img v-if="currentRichiesta.Persona.Immagine" alt="Avatar" :src="imagesPathPerson + currentRichiesta.Persona.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
								<avatar v-else :username="currentRichiesta.Persona.FullName" :size="35" />
							</v-avatar>
							<label style="margin-left:10px">{{ currentRichiesta.Persona.FullName }}</label>
						</v-col>
						<v-col cols="12" lg="12">
							<code style="margin-top:20px; margin-bottom:20px; padding:10px; display:block">{{ currentRichiesta.DescrizioneRichiesta }}</code>
						</v-col>
						<v-col cols="12" lg="12">
							<v-select v-model="currentRichiesta.StatoRichiestaFormazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Stato richiesta" :items="statiRichiestaFormazione" item-text="Label" item-value="Codice" item-color="Color" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea ref="autofocus" v-model="currentRichiesta.DescrizioneRisposta" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Risposta" />
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog richiesta -->						
		
		<v-snackbar v-model="snackbarVisible" :color="snackbarColor" top :timeout="4000">
			{{ snackbarText }}
		</v-snackbar>		
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** ADMIN FORMAZIONE *************************************************************************
***********************************************************************************************
*/
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import Avatar from 'vue-avatar'
// import HrDatePicker from '@/components/hr-date-picker'
import HrFormazioneRangeDatePicker from '@/components/hr-formazione-range-date-picker'
import StandardLabel from '@/components/standard-label'
import HrAnnoRiferimento from '@/components/hr-anno-riferimento'
import HrCalendarioCorsi from '@/components/hr-calendario-corsi'
import moment from 'moment'
import lodash from 'lodash'
// import Loading from '@/components/loading'
export default {
	name: 'AdminFormazionePage',
	components: { StandardContainer, DebugPanel, HrFormazioneRangeDatePicker, StandardLabel, HrAnnoRiferimento, HrCalendarioCorsi, Avatar },
	data: () => {
		return {
			currentTab:0,
			fabVisible: true,
			annoCorrente: parseInt(moment().format('YYYY')),
			snackbarVisible: false,
			snackbarText: '',
			snackbarColor: 'info',
			// CORSI ----------------------------------------------------------------
			loadingCorsi: false,
			corsi: [],
			dialogCorso: false,
			currentCorso: {},
			errorCorso: '',
			tableHeaderCorsi: [
				{ text: '', value: 'Id' },
				{ text: 'Codice', value: 'Codice' },
				{ text: 'Job', value: 'IdJob' },
				{ text: 'Finalità', value: 'CodiceFinalita' },
				{ text: 'Sede principale', value: 'IdSedePrincipale' },
				{ text: 'Docente principale', value: 'IdDocentePrincipale' },
				{ text: 'PrimaDataUtile', value: 'PrimaDataUtile' },
				{ text: 'UltimaDataUtile', value: 'UltimaDataUtile' },
				// { text: 'Trimestre', value: 'Trimestre' },
				// { text: 'Costo standard', value: 'Costo' },
				{ text: '', value: 'IsEliminable' },
			],
			// CALENDARIO ----------------------------------------------------------------
			loadingCalendario: false,
			calendario: [],
			selectedEvent: {},
			selectedElement: null,
			selectedOpen: false,
			// DOCENTI --------------------------------------------------------------
			loadingDocenti: false,
			docenti:[],
			dialogDocente: false,
			currentDocente: {},
			errorDocente: '',
			tableHeaderDocenti: [
				{ text: 'Nome', value: 'Nome' },
				{ text: 'Interno/esterno', value: 'Interno' },
				{ text: '', value: 'IsEliminable' },
			],
			// SEDI -----------------------------------------------------------------
			loadingSedi: false,
			sedi: [],
			dialogSede: false,
			currentSede: {},
			errorSede: '',
			tableHeaderSedi: [
				{ text: 'Nome', value: 'Nome' },
				{ text: '', value: 'IsEliminable' },
			],
			// RICHIESTE ------------------------------------------------------------------
			loadingRichieste: false,
			richieste: [],
			dialogRichiesta: false,
			currentRichiesta: { Persona: {} },
			errorRichiesta: '',
			statiRichiestaFormazione: [
				{Codice:10, Label:'Nuova', Color:'primary'},
				{Codice:20, Label:'In valutazione', Color:'warning'},
				{Codice:30, Label:'Programmata', Color:'success'},
				{Codice:40, Label:'Effettuata', Color:'success'},
				{Codice:50, Label:'Rifiutata', Color:'danger'},
			],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		calendarioColori() { return this.$store.getters['calendarioColori'] },
		loading() { return this.loadingCorsi || this.loadingDocenti || this.loadingSedi || this.loadingCalendario || this.loadingRichieste },
		persone() { return this.$store.getters['persone'] },
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		richiesteExt() {
			var richieste = JSON.parse(JSON.stringify(this.richieste))
			richieste.forEach(item => {
				item.Persona = this.persone.find(p => {return p.Id === item.IdPersona}) // filtra via le richieste di persone non più presenti (cancellate o altro)
			})
			return richieste.filter(item => {return item.Persona})
		},
		corsiConDateOrdinate() {
			var corsiDecoupled = JSON.parse(JSON.stringify(this.corsi))
			corsiDecoupled.forEach(i => {
				i.Date = lodash.orderBy(i.Date, 'Inizio')
			})
			return corsiDecoupled
		}
	},
	watch:{
		currentTab(to, from) {
			// giochino sporco per far capire all'utente che il fab (che non si sposta) è realativo alla tab corrente e non globale
			// quindi lo nascondiamo giusto il tempo di fare lo spostamento di tab
			if(to!==from) {
				this.fabVisible = false
				setTimeout(()=>{ this.fabVisible = true },500)
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		loadData() {
			// carica tutto
			this.loadCorsi()
			this.loadDocenti()
			this.loadSedi()
			this.loadCalendario()
			this.loadRichieste()
		},
		changeAnnoCorrente() {
			// console.warn('changeAnnoCorrente', event)
			this.loadCorsi()
			this.loadCalendario()
		},
		// decodifiche -----------------------------------------------------------------
		decodificaTrimestre(codiceTrimestre) {
			var dummy = this.decodifiche.FormazioneTrimestre.find(i=>{return i.Key === codiceTrimestre})
			if(dummy) return dummy.Value
			return '-'
		},
		decodificaDescrizioneJob(idJob) {
			var dummy = this.decodifiche.Jobs.find(i=>{return i.Id === idJob})
			if(dummy) return dummy.DescrizioneJob
			return '-'
		},
		decodificaFinalita(codiceFinalita) {
			var dummy = this.decodifiche.FormazioneFinalita.find(i=>{return i.Key == codiceFinalita})
			if(dummy) return dummy.Value
			return '-'
		},
		// decodifica l'intero corso, serve per dare un po di dettaglio nel calendario
		decodificaCorso(idCorso) {
			var dummy = this.corsi.find(i=>{return i.Id == idCorso})
			if(dummy) return dummy
			return null
		},
		// CORSI ----------------------------------------------------------------
		loadCorsi() {
			this.loadingCorsi = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Corsi/List', value: {Anno: this.annoCorrente}}).then(data => {
				this.corsi = data
				this.loadingCorsi = false
			}).catch(error => {
				console.error('loadCorsi', error)
				this.loadingCorsi = false
			})
		},
		aggiungiCorso() {
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Corsi/New'}).then(data => {
				this.currentCorso = data
				this.currentCorso.AnnoRiferimento = this.annoCorrente
				this.$set(this.currentCorso, 'New', true)
				this.dialogCorso = true
			}).catch(error => {
				console.error('aggiungiCorso', error)
			})
		},
		eliminaCorso(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare il corso "'+item.Nome+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loadingCorsi = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Corsi/Delete', value: item}).then(data => {
						this.corsi = data
						this.loadingCorsi = false
						this.loadCalendario()
					}).catch(error => {
						console.error('eliminaCorso', error)
					})
				}
			})
		},
		editCorsoFromCalendario(calendarioEvent){
			this.editCorso(this.decodificaCorso(calendarioEvent.id))
		},
		editCorso(item) {
			this.errorCorso = ''
			this.currentCorso = JSON.parse(JSON.stringify(item))
			this.dialogCorso = true
		},
		updateCorso() {
			this.errorCorso = ''
			this.loadingCorsi = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Corsi/Save', value: this.currentCorso}).then(data => {
				this.corsi = data
				this.loadingCorsi = false
				this.dialogCorso = false
				this.loadCalendario()
			}).catch(error => {
				console.error('updateCorso', error)
				this.loadingCorsi = false
				this.errorCorso = error
			})
		},
		async aggiungiData() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			var data = {
				Id: negativeId,
				Nome: '',
				Descrizione: '',
				Inizio: moment().format('YYYY-MM-DD'),
				Fine: moment().format('YYYY-MM-DD'),
				IdSede: this.currentCorso.IdSedePrincipale,
				IdDocente: this.currentCorso.IdDocentePrincipale,
				Delete: false,
				IsEliminable: true,
				OreData: 8 // default 1gg
			}
			this.currentCorso.Date.unshift(data)
		},
		// evento scatenato al cambio della data Inizio e Fine nel blocco date. 
		// lo usiamo per verificare che la data fine non resti mai indietro e, se fosse, la riaggiorniamo
		// nel contempo calcoliamo anche il numero di ore totali usando una api che ci restituisce il numero di gg lavorativi
		// l'envento ci manda due parametri: 
		//				il primo contiene un oggetto con {oldValue: vecchia data e newValue: nuova data selezionata} 
		//				il secondo è l'intero oggetto Data che contiente quindi anche la data Fine
		changeDateInizioFine(dateChange, data) {
			// console.warn('changeDateInizio', dateChange.newValue > data.Fine)
			if(data.Inizio && data.Fine) {
				if(dateChange.newValue > data.Fine) data.Fine = data.Inizio
				this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/GetGiorniLavorativi', value: data}).then(response => {
					console.warn('gg lavorativi', response)
					data.OreData = 8 * response.Totali.TotaleGiorniLavorativi
					this.snackbarText = 'Ricalcolate ore data corso: ' + response.Totali.TotaleGiorniLavorativi + ' giorni lavorativi * 8 = ' + data.OreData + ' ore'
					this.snackbarVisible = true
				}).catch(error => {
					console.error('GetGiorniLavorativi', error)
				})
			}
		},
		// CALENDARIO ----------------------------------------------------------------
		loadCalendario() {
			this.loadingCalendario = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Corsi/Calendario', value: {Anno: this.annoCorrente}}).then(data => {
				this.calendario = data
				this.loadingCalendario = false
			}).catch(error => {
				console.error('loadCalendario', error)
				this.loadingCalendario = false
			})
		},
		// DOCENTI --------------------------------------------------------------
		loadDocenti() {
			this.loadingDocenti = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Docenti/List'}).then(data => {
				this.docenti = data	
				this.loadingDocenti = false
			}).catch(error => {
				console.error('loadDocenti', error)
				this.loadingDocenti = false
			})
		},
		aggiungiDocente() {
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Docenti/New'}).then(data => {
				this.currentDocente = data
				this.$set(this.currentDocente, 'New', true)
				this.dialogDocente = true
			}).catch(error => {
				console.error('aggiungiDocente', error)
			})
		},
		eliminaDocente(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare il docente "'+item.Nome+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loadingDocenti = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Docenti/Delete', value: item}).then(data => {
						this.docenti = data
						this.loadingDocenti = false
					}).catch(error => {
						console.error('eliminaDocente', error)
					})
				}
			})
		},
		editDocente(item) {
			this.errorDocente = ''
			this.currentDocente = JSON.parse(JSON.stringify(item))
			this.dialogDocente = true
		},
		updateDocente() {
			this.errorDocente = ''
			this.loadingDocenti = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Docenti/Save', value: this.currentDocente}).then(data => {
				this.docenti = data
				this.loadingDocenti = false
				this.dialogDocente = false
			}).catch(error => {
				console.error('updateDocente', error)
				this.loadingDocenti = false
				this.errorDocente = error
			})
		},
		decodificaNomeDocente(idDocente) {
			var docente = this.docenti.find(i => {return i.Id === idDocente})
			if(docente) return docente.Nome
			return '-'
		},
		decodificaTipoDocente(idDocente) {
			var docente = this.docenti.find(i => {return i.Id === idDocente})
			if(docente) return docente.Interno
			return '-'
		},
		// SEDI -----------------------------------------------------------------
		loadSedi() {
			this.loadingSedi = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Sedi/List'}).then(data => {
				this.sedi = data
				this.loadingSedi = false
			}).catch(error => {
				console.error('loadSedi', error)
				this.loadingSedi = false
			})
		},
		aggiungiSede() {
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Sedi/New'}).then(data => {
				this.currentSede = data
				this.$set(this.currentSede, 'New', true)
				this.dialogSede = true
			}).catch(error => {
				console.error('aggiungiSede', error)
			})
		},
		eliminaSede(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare la sede "'+item.Nome+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loadingSedi = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Sedi/Delete', value: item}).then(data => {
						this.sedi = data
						this.loadingSedi = false
					}).catch(error => {
						console.error('eliminaSede', error)
					})
				}
			})
		},
		editSede(item) {
			this.errorSede = ''
			this.currentSede = JSON.parse(JSON.stringify(item))
			this.dialogSede = true
		},
		updateSede() {
			this.errorSede = ''
			this.loadingSedi = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Sedi/Save', value: this.currentSede}).then(data => {
				this.sedi = data
				this.loadingSedi = false
				this.dialogSede = false
			}).catch(error => {
				console.error('updateSede', error)
				this.loadingSedi = false
				this.errorSede = error
			})
		},
		decodificaNomeSede(idSede) {
			var sede = this.sedi.find(i => {return i.Id === idSede})
			if(sede) return sede.Nome
			return '-'
		},
		// 
		// RICHIESTE -----------------------------------------------------------------
		loadRichieste() {
			this.loadingRichieste = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Richieste/List'}).then(data => {
				this.richieste = data
				this.loadingRichieste = false
			}).catch(error => {
				console.error('loadRichieste', error)
				this.loadingRichieste = false
			})
		},
		editRichiesta(item) {
			this.errorRichiesta = ''
			this.currentRichiesta = JSON.parse(JSON.stringify(item))
			this.dialogRichiesta = true
		},
		updateRichiesta() {
			this.errorRichiesta = ''
			this.loadingRichieste = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/Richieste/Save', value: this.currentRichiesta}).then(data => {
				this.richieste = data
				this.loadingRichieste = false
				this.dialogRichiesta = false
			}).catch(error => {
				console.error('updateRichiesta', error)
				this.loadingRichieste = false
				this.errorRichiesta = error
			})
		},
	},
}
</script>

<style scoped lang="less">
	.opaque{ 
		opacity: 0.5;
	}
</style>