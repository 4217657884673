<template>
	<div>
		<v-menu
			v-model="menu1"
			:close-on-content-click="false"
			max-width="290"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					:value="computedDateFormattedMomentjs"
					:clearable="clearable"
					:label="label"
					readonly
					:filled="filled"
					:solo="solo"
					:dense="dense"
					:outlined="outlined"
					:hide-details="hideDetails"
					:disabled="disabled"
					:style="'min-width:' + (shortDate ? '220' : '310') + 'px'"
					v-on="on"
					@click:clear="clearDate"
				/>
			</template>
			<v-date-picker
				v-model="dateRange"
				range
				locale="it-it"
				:first-day-of-week="1"
				reactive
				:min="min"
				:max="max"
				:events="functionEvents"
			/>
		</v-menu>
		<div v-if="viewDebugElements">
			<code>inizio: {{ inizio }}</code><br>
			<code>fine: {{ fine }}</code><br>
			<code class="mt-1">dateRange: {{ dateRange }}</code>
			<code v-if="min" class="mt-1">min: {{ min }}</code>
			<code v-if="max" class="mt-1">max: {{ max }}</code>
		</div>
	</div>
</template>

<script>
/**********************************************************************************************
**** COMPONETE RANGE DATE PICKER PER LA FORMAZIONE ********************************************
***********************************************************************************************
* consente l'uso del range date piker con i parametri standard della foramzione (date Inizio e Fine)
* e visualizza anche gli altri corsi tramite la funzione event del date picker vuetify
*
* N.B. non usa il normale binding via v-model poiché deve gestire due date: Inizio e Fine
*		è quindi necessario ricorrere al .sync (vedi qui se non ti ricordi cos'è: https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier)
*		esempio:
*					:inizio.sync="item.Inizio" :fine.sync="item.Fine"
*/
import moment from 'moment'
export default {
	name: 'HrFormazioneRangeDatePicker',
	props: {
		// data inizio
		inizio: {
			type: String,
			default: () => {return null}
		},
		// data fine
		fine: {
			type: String,
			default: () => {return null}
		},
		// se true visualizza gli altri corsi (passati in calendario) come pallini colorati (events del data-picker vuetify)
		showEvents: {
			type: Boolean,
			default: () => {return false}
		},
		// calendario eventi da visualizzare se attivo showEvent
		calendario: {
			type: Object,
			default: () => {return {}}
		},
		// se true visualizza le date con il mese accorciato a tre caratteri
		shortDate: {
			type: Boolean,
			default: () => {return false}
		},
		// data minima selezionabile
		min: {
			type: String,
			default: () => {return undefined}
		},
		// data massima selezionabile
		max: {
			type: String,
			default: () => {return undefined}
		},
		// se true il clear (abilitato con clearable) porta il datarange a null
		// se false invece usa la data corrente (default = true)
		clearableAtNull: {
			type: Boolean,
			default: () => {return true}
		},
		label :{
			type: String,
			default: () => {return 'Data'}
		},
		solo: {
			type: Boolean,
			default: () => {return false}
		},
		filled: {
			type: Boolean,
			default: () => {return false}
		},
		dense: {
			type: Boolean,
			default: () => {return false}
		},
		outlined: {
			type: Boolean,
			default: () => {return false}
		},
		hideDetails: {
			type: Boolean,
			default: () => {return false}
		},
		disabled: {
			type: Boolean,
			default: () => {return false}
		},
		clearable: {
			type: Boolean,
			default: () => {return false}
		},
	},
	data: () => {
		return {
			menu1: false,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		computedDateFormattedMomentjs () {
			moment.locale('it')
			var result = ''
			result += this.dateRange[0] ? moment(this.dateRange[0]).format(this.shortDate ? 'DD MMM YYYY' : 'DD MMMM YYYY') : '-'
			result += ' - '
			result += this.dateRange[1] ? moment(this.dateRange[1]).format(this.shortDate ? 'DD MMM YYYY' : 'DD MMMM YYYY') : '-'
			return result
		},
		// compone la struttura date come richiesto da vuetify per i range 
		// in fase di salvvataggio emette anche gli eventu necessary per il .sync delle prop
		dateRange: {
			get: function () {
				var result = []
				if (this.inizio) result.push(moment(this.inizio).format('YYYY-MM-DD'))
				if (this.fine) result.push(moment(this.fine).format('YYYY-MM-DD'))
				return result
			},
			set: function (newValue) {
				var oldValue = [this.inizio, this.fine]
				if(newValue.length===2) {
					// due date selezionate: chiudiamo ed emettiamo tutti gli eventi
					// console.warn('range 2', newValue)
					// attenzione all'ordine corretto delle date!
					var dataInizio = newValue[0] < newValue[1] ? newValue[0] : newValue[1]
					var dataFine = newValue[0] < newValue[1] ? newValue[1] : newValue[0]
					this.$emit('update:inizio', moment(dataInizio).format('YYYY-MM-DD') + 'T00:00:00')
					this.$emit('update:fine', moment(dataFine).format('YYYY-MM-DD') + 'T23:59:59')
					this.menu1 = false
					this.$emit('change', {oldValue: oldValue, newValue: [this.inizio, this.fine]}) 
				} else if(newValue.length===1){
					// una sola data selezionata, in base allo stato delle variabili sync si decide se è la prima selezione oppure una successiva modifica del range
					// in caso di modifiche successive facciamo in modo che cliccando sulle date di inizio e fine queste si resettino per consentinre il cambio
					var data = newValue[0]
					if(this.inizio) {
						// console.warn('range 1 (inizio NOT null)', newValue, this.inizio, this.fine)
						// inizio è già valorizzata, quindi confrontiamola con la data seleziona e verifichiamo per prima cosa una coincidenza con le due date estreme
						if(moment(data).format('YYYY-MM-DD') === moment(this.inizio).format('YYYY-MM-DD') && moment(data).format('YYYY-MM-DD') === moment(this.fine).format('YYYY-MM-DD')){
							// selezionata data di inizio che coincide anche con quella di fine, azzeriamole e procediamo senza chiudere 
							this.$emit('update:inizio', null)
							this.$emit('update:fine', null)
						} else if(moment(data).format('YYYY-MM-DD') === moment(this.inizio).format('YYYY-MM-DD')){
							// selezionata data di inizio, azzeriamola e procediamo senza chiudere 
							this.$emit('update:inizio', null)
						} else if(moment(data).format('YYYY-MM-DD') === moment(this.fine).format('YYYY-MM-DD')) {
							// selezionata data di fine, azzeriamola e procediamo senza chiudere 
							this.$emit('update:fine', null)
						} else if(moment(data).format('YYYY-MM-DD') < moment(this.inizio).format('YYYY-MM-DD')) {
							// selezionata data minore di quella iniziale e quindi aggiorniamo inizio e chiudiamo
							this.$emit('update:inizio', moment(data).format('YYYY-MM-DD') + 'T00:00:00')
							this.menu1 = false
							this.$emit('change', {oldValue: oldValue, newValue: [this.inizio, this.fine]}) 
						} else {
							// selezionata data maggiore a quella iniziale e quindi aggiorniamo fine e chiudiamo
							this.$emit('update:fine', moment(data).format('YYYY-MM-DD') + 'T00:00:00')
							this.menu1 = false
							this.$emit('change', {oldValue: oldValue, newValue: [this.inizio, this.fine]}) 
						}
					} else {
						// situazione iniziale (o dopo clear) senza date selezionate, quindi selezioniamo inizio e proseguiamo senza chiudere
						// console.warn('range 1 (inizio null)', newValue, this.inizio, this.fine)
						this.$emit('update:inizio', moment(data).format('YYYY-MM-DD') + 'T00:00:00')
					}
				} else {
					// in tutti gli altri casi azzeriamo
					// console.warn('range != 2', newValue)
					this.$emit('update:inizio', null)
					this.$emit('update:fine', null)
					this.menu1 = false // non serve ma non fa male
					this.$emit('change', {oldValue: oldValue, newValue: []}) 
				}
			}
		},
	},
	created() {
		moment.locale('it')
	},
	methods: {
		// al clear azzeriamo 
		clearDate() {
			this.dateRange = []
		},
		// se la proprietà "showEvents" è true restituisce per ogni giorno richiesto tanti colori quanti sono i corsi presenti nel calendario del giorno (vedere nmeccanismo di gestioeneventi del datapicker di vuetify)
		functionEvents(date) {
			if(this.showEvents && this.calendario && this.calendario.Eventi) {
				// console.warn('functionEvents date', date)
				var dummy = this.calendario.Eventi.filter(i => {
					return i.start <= date && i.end>= date
				}).map(i => { return i.color})
				// console.warn('functionEvents dummy', dummy)
				return dummy
			}else 
			{
				return null
			}
		}
	}	
}
</script>

<style scoped lang="less">

</style>